
















import defaultComponent from "../../utils/defaultComponent";
import { debounce } from "debounce";

export default defaultComponent.apply({
  data() {
    return {
      fieldData: {},
      dirtyValue: false,
      debouncedTriggerUpdate: debounce(() => {
        this.triggerUpdate();
      }, 500),
    };
  },
  methods: {
    onSearch() {
      this.debouncedTriggerUpdate();
    },
    triggerUpdate() {
      this.$emit("search");
    },
  },
  extraOptions: {
    forwardStates: {
      xSearch: ["search"],
    },
  },
});
