var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-notify-snack-bar',{attrs:{"x-options":_vm.options.content.errorNotifySnackBar,"x-error":_vm.error}}),_c('x-notify-snack-bar',{attrs:{"x-options":_vm.options.content.infoNotifySnackBar,"x-error":_vm.info}}),(
      _vm.options.content.name &&
      !_vm.options.content.hiddenName &&
      _vm.options.content.layout === 'layout2'
    )?[_c('v-row',{staticClass:"mb-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('h2',[_vm._v(" "+_vm._s(_vm.options.content.name)+" ")]):_c('h3',{staticClass:"text-no-wrap text-truncate"},[_vm._v(" "+_vm._s(_vm.options.content.name)+" ")]),_vm._t("description",function(){return [(_vm.options.content.description)?_c('h5',[_vm._v(" "+_vm._s(typeof _vm.options.content.description === "function" ? _vm.options.content.description(_vm.self) : _vm.options.content.description)+" ")]):_vm._e()]})],2),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"8"}},[(_vm.model.value && _vm.model.value.length)?[_vm._l((_vm.options.content.multiSelectActionButtons),function(btn,key){return [(
                btn &&
                (!btn.ext ||
                  (!btn.ext.hidden &&
                    (!btn.ext.condition || btn.ext.condition(_vm.self))))
              )?_c('x-btn',_vm._b({key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-default-class":"dataTable.content.template.topActionButton"}},'x-btn',btn.attrs,false)):_vm._e()]})]:_vm._e(),_vm._l((_vm.sortedTopActionButtons),function(btnWrapper){return [(
              btnWrapper.v &&
              (!btnWrapper.v.ext ||
                (!btnWrapper.v.ext.hidden &&
                  (!btnWrapper.v.ext.condition ||
                    btnWrapper.v.ext.condition(_vm.self))))
            )?_c('x-btn',{key:btnWrapper.k,attrs:{"x-options":btnWrapper.v,"x-context":_vm.self,"x-default-class":"dataTable.content.template.topActionButton"}}):_vm._e()]})],2)],1)]:_vm._e(),_vm._t("top"),(
      _vm.options.content.filters && !_vm._.isEmpty(_vm.options.content.filters.filters)
    )?_c('filters',{attrs:{"x-options":_vm.options.content.filters,"x-filters":_vm.search.filters,"x-container":_vm.filtersContainer,"x-context":_vm.self,"layout":_vm.options.content.layout},on:{"search":_vm.onQueryChanged,"heightChanged":_vm.recalculateHeight}}):_vm._e(),_c(_vm.options.content.layout === 'layout2' ? 'div' : 'v-card',_vm._b({tag:"component"},'component',_vm.options.content.mainCard.attrs,false),[_c('v-data-table',_vm._g(_vm._b({ref:"dataTable",class:[_vm.options.attrs.class],attrs:{"expanded":_vm.expanded.value,"headers":_vm.headers,"items":_vm.items.value,"search":_vm.search.value,"options":_vm.dataOptions,"server-items-length":_vm.itemCount.value,"loading":_vm.loading.value,"height":_vm.height},on:{"update:options":function($event){_vm.dataOptions=$event},"page-count":function($event){_vm.pageCount.value = $event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"top",fn:function(){return [(
            !_vm.options.content.mainToolbar.ext ||
            !_vm.options.content.mainToolbar.ext.hidden
          )?_c('v-toolbar',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.options.content.layout),expression:"!options.content.layout"}]},'v-toolbar',_vm.options.content.mainToolbar.attrs,false),[(
              _vm.options.content.name &&
              !_vm.options.content.hiddenName &&
              !_vm.options.content.layout
            )?[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.options.content.name)+" "),_vm._t("description",function(){return [(_vm.options.content.description)?_c('h5',[_vm._v(" "+_vm._s(typeof _vm.options.content.description === "function" ? _vm.options.content.description(_vm.self) : _vm.options.content.description)+" ")]):_vm._e()]})],2),(_vm.options.content.nameDivider)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_c('v-spacer')]:_vm._e(),_vm._t("search.prepend"),(
              !_vm.options.content.search.ext ||
              !_vm.options.content.search.ext.hidden
            )?_c('search',{attrs:{"x-options":_vm.options.content.search,"x-search":_vm.search.search,"x-container":_vm.searchContainer,"x-context":_vm.self},on:{"search":_vm.onQueryChanged}}):_vm._e(),_vm._t("search.append"),_c('v-spacer'),(!_vm.options.content.layout)?[(_vm.model.value && _vm.model.value.length)?[_vm._l((_vm.options.content.multiSelectActionButtons),function(btn,key){return [(
                    btn &&
                    (!btn.ext ||
                      (!btn.ext.hidden &&
                        (!btn.ext.condition || btn.ext.condition(_vm.self))))
                  )?_c('x-btn',_vm._b({key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-default-class":"dataTable.content.template.topActionButton"}},'x-btn',btn.attrs,false)):_vm._e()]})]:_vm._e(),_vm._l((_vm.sortedTopActionButtons),function(btnWrapper){return [(
                  btnWrapper.v &&
                  (!btnWrapper.v.ext ||
                    (!btnWrapper.v.ext.hidden &&
                      (!btnWrapper.v.ext.condition ||
                        btnWrapper.v.ext.condition(_vm.self))))
                )?_c('x-btn',{key:btnWrapper.k,attrs:{"x-options":btnWrapper.v,"x-context":_vm.self,"x-default-class":"dataTable.content.template.topActionButton"}}):_vm._e()]})]:_vm._e()],2):_vm._e()]},proxy:true},(_vm.options.content.template.itemActionButtons)?{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"150px"}},[_vm._l((_vm.options.content.template.itemActionButtons),function(btn,key){return [(
                btn &&
                (!btn.ext ||
                  (!btn.ext.hidden &&
                    (!btn.ext.condition || btn.ext.condition(_vm.self, item))))
              )?_c('x-btn',_vm._b({key:key,attrs:{"x-options":btn,"x-context":{ item: item, dataTable: _vm.self },"x-default-class":"dataTable.content.template.itemActionButton"}},'x-btn',
                btn.ext && btn.ext.makeAttrs && btn.ext.makeAttrs(item, _vm.self)
              ,false)):_vm._e()]})],2)]}}:null,_vm._l((_vm.itemFieldSlots),function(field,key){return {key:key,fn:function(slotData){return [(field.options && field.options.index)?_c('span',{key:(key + "_index"),staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentItemsPerPage * (_vm.currentPage - 1) + _vm.items.value.indexOf(slotData.item) + 1)+" ")]):(field.options && field.options.localIndex)?_c('span',{key:(key + "_index"),staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.items.value.indexOf(slotData.item) + 1)+" ")]):_c('x-display',{key:key,attrs:{"value":slotData.value,"item":slotData.item,"x-options":field.options}})]}}}),{key:"header.data-table-select",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('v-simple-checkbox',_vm._g(_vm._b({},'v-simple-checkbox',Object.assign({}, props, _vm.options.content.template.checkbox.attrs),false),on))]}},{key:"footer.prepend",fn:function(){return [(_vm.model && _vm.model.value && _vm.model.value.length)?_c('span',[_vm._v(" "+_vm._s(_vm.model.value.length)+" selected ")]):_vm._e(),(_vm.options.content.customPerPageEnabled)?_c('span',[_c('v-text-field',{attrs:{"label":"Per page","type":"number","dense":"","hide-details":"","outlined":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onQueryChanged.apply(null, arguments)}},model:{value:(_vm.customPerPage),callback:function ($$v) {_vm.customPerPage=$$v},expression:"customPerPage"}})],1):_vm._e()]},proxy:true},{key:"item.data-table-select",fn:function(ref){
              var item = ref.item;
              var isSelected = ref.isSelected;
              var select = ref.select;
return [(
            !_vm.options.content.itemSelectCondition ||
            _vm.options.content.itemSelectCondition(item)
          )?_c('v-simple-checkbox',_vm._b({attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}},'v-simple-checkbox',_vm.options.content.template.checkbox.attrs,false)):_vm._e()]}}],null,true),model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},'v-data-table',_vm.options.attrs,false),_vm.options.on),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }